// import { FC } from "react";
// import Main from "../pages/main/main";

import { AreaChartOutlined, ArrowUpOutlined, CaretUpFilled } from "@ant-design/icons";
import Article from "../pages/article/article";
import Category from "../pages/categary/category";
import Login from "../pages/login/login";
import Statistics from "../pages/statistics/statistics";

export type MyRoute = {
  path: string,
  exact: boolean,
  nameEn: string,
  nameTh: string,
  label?: string,
  isVisible: boolean,
  roles?: string[],
  icon?: any,
  component: any
};

const routes: MyRoute[] = [
  {
    path: '/',
    exact: true,
    nameEn: 'statistics',
    nameTh: 'สถิติ',
    label: 'หน้าสถิติ',
    isVisible: true,
    icon: <AreaChartOutlined />,
    component: Statistics,
  },
  {
    path: '/category',
    exact: true,
    nameEn: 'Category',
    nameTh: 'หมวดหมู่บทความ',
    isVisible: true,
    label: 'หน้าหมวดหมู่',
    icon: <CaretUpFilled />,
    component: Category,
  },
  {
    path: '/article',
    exact: true,
    nameEn: 'Article',
    nameTh: 'บทความ',
    isVisible: true,
    label: 'หน้าบทความ',
    icon: <ArrowUpOutlined />,
    component: Article,
  },
  {
    path: '/login',
    exact: true,
    nameEn: 'login',
    nameTh: 'เข้าสู่ระบบ',
    isVisible: false,
    label: 'หน้าเข้าสู่ระบบ',
    component: Login,
  },
  // {
  //   path: '/rules',
  //   exact: true,
  //   name: 'rules',
  //   label: 'กติกาการเล่น',
  //   isVisible: true,
  //   roles: ['header', 'member'],
  //   component: Rules,
  // },
];

export default routes;
