import React, { Component } from 'react';
import { Switch, Route, HashRouter } from "react-router-dom";
import Login from './pages/login/login';
import Main from './pages/main/main';


class App extends Component {

  render() {
    return (
      <HashRouter>
        <Switch>
          <Route exact path="/" component={Main} />
          <Route path="/login" component={Login} />
        </Switch>
      </HashRouter>
    );
  }
}

export default App;