import axios, { AxiosInstance } from 'axios';
import { IArticle } from '../types/article.typs';
import { ICategory } from '../types/category.types';
import { ILottoHistory } from '../types/lotto-history.types';


const apiClient: AxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  // baseURL: `https://op8vg1apd5.execute-api.ap-southeast-1.amazonaws.com/prod`,
  withCredentials: false, // This is the default
  timeout: 60000
});

export const getLotto = async () => {
  return await apiClient.get('/lotto');
}

export const login = async (username: string, password: string) => {
  const values = {
    username,
    password
  };

  const response = await apiClient.post('auth/login', values);
  return response;
}

export const logout = async () => {
  const token = localStorage.getItem('token');

  await apiClient.get('/auth/logout/' + token);

  localStorage.removeItem('token');
  localStorage.removeItem('name');
  localStorage.removeItem('memberId');
}

export const getLottoHistoryByLottoId = async (id: number | undefined) => {
  const query = '?filter=lotto.id||$eq||' + id + '&sort=date,DESC';
  const response = await apiClient.get('/lotto-history' + query);
  return response;
}

export const updateLottoHistory = async (id: number | undefined, data: any) => {
  const response = await apiClient.patch('/lotto-history/' + id, data);
  return response;
}

export const createNewLottoHistory = async (lottoHistory: ILottoHistory) => {
  const response = await apiClient.post('/lotto-history', lottoHistory);
  return response;
}

export const deleteLottoHistory = async (id: number|undefined) => {
  const response = await apiClient.delete('/lotto-history/' + id);
  return response;
}

export const getAllCategories = async () => {
  const response = await apiClient.get('/category');
  return response;
}

export const createNewCategory = async (category: ICategory) => {
  const response = await apiClient.post('/category', category);
  return response;
}

export const deleteCategory = async (id: number|undefined) => {
  const response = await apiClient.delete('/category/' + id);
  return response;
}

export const updateCategory = async (id: number | undefined, data: any) => {
  const response = await apiClient.patch('/category/' + id, data);
  return response;
}

export const getArticlesByCategoryId = async (id: number | undefined) => {
  const query = '?category=' + id;
  const response = await apiClient.get('/article/get-articles' + query);
  return response;
}

export const createNewArticle = async (article: IArticle) => {
  const response = await apiClient.post('/article', article);
  return response;
}

export const deleteArticle = async (id: number|undefined) => {
  const response = await apiClient.delete('/article/' + id);
  return response;
}

export const updateArticle = async (id: number | undefined, data: any) => {
  const response = await apiClient.patch('/article/' + id, data);
  return response;
}