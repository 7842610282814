import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Button, Empty, Form, Input, Modal, Space, Spin, Table, Tag, Typography } from "antd";
import { ColumnsType } from "antd/lib/table";
import React, { FC, useCallback, useEffect, useState } from "react";
import Notification from "../../components/Notification";
import { createNewCategory, deleteCategory, getAllCategories, updateCategory } from "../../services/services";
import { ICategory } from "../../types/category.types";

const { Title } = Typography;

const renderCategoryColumns = (onEditCategory: Function, onConfirmDeleteCategory: Function) => {
    const columns: ColumnsType<ICategory> = [
        {
            title: () => <b>ลำดับ</b>,
            dataIndex: 'id',
            key: 'id',
            align: 'center',
            width: 120,
            render: (text: string, record: ICategory, index: number) => {
                return <>{index + 1}</>
            }
        },
        {
            title: () => <b>ชื่อหมวดหมู่</b>,
            dataIndex: 'name',
            key: 'name',
            render: (text: string, record: ICategory, index: number) => {
                return text ? text : <Tag color="orange">{record.name}</Tag>;
            }
        },
        {
            title: () => <b>จัดการ</b>,
            key: 'action',
            fixed: 'right',
            render: (text: string, record: ICategory, index: number) => (
                <Space size="middle">
                    <Button size="small" type="primary" onClick={() => onEditCategory(record)}>แก้ไข</Button>
                    <Button size="small" danger onClick={() => onConfirmDeleteCategory(record)}>ลบ</Button>
                </Space>
            ),
        },
    ];

    return columns;
}


const layout = {
    labelCol: {
        span: 4,
    },
    wrapperCol: {
        span: 16,
    },
};

const Category: FC = () => {
    // form
    const [formAdd] = Form.useForm();
    const [formEdit] = Form.useForm();

    const [loading, setLoading] = useState(true);
    const [categories, setCategories] = useState<ICategory[] | null>(null);
    const [showAddCategory, setShowAddCategory] = useState(false);
    const [loadingForm, setLoadingForm] = useState(false);
    const [selectdCategory, setSelectdCategory] = useState<ICategory | null>(null);
    const [showEditCategory, setShowEditCategory] = useState(false);

    const getCategories = useCallback(async () => {
        const response = await getAllCategories();
        setCategories(response.data?.data);
    }, []);

    useEffect(() => {
        getCategories().then(() => setLoading(false)).catch(() => setLoading(false));
    }, [getCategories])

    const onAddNewCategory = async () => {
        formAdd.resetFields();
        setShowAddCategory(true);
    }

    const onConfirmAddNewCategory = () => {
        Modal.confirm({
            title: 'Confirm',
            icon: <ExclamationCircleOutlined />,
            content: 'ต้องการบันทึกข้อมูลใช่หรือไม่?',
            okText: 'บันทึก',
            cancelText: 'ยกเลิก',
            onOk: () => onSaveNewCategory()
        });
    }

    const onSaveNewCategory = async () => {
        setLoadingForm(true);
        formAdd.validateFields().then(async (value) => {
            const data: ICategory = formAdd.getFieldsValue();

            const response = await createNewCategory(data);
            if (response.status === 201) {
                await getCategories();
                Notification('success', 'บันทึกสถิติสำเร็จ');
            } else {
                Notification('error', 'บันทึกสถิติไม่สำเร็จ');
            }
            setShowAddCategory(false);
            setLoadingForm(false);
        }).catch((reason) => {
            Notification('error', 'บันทึกสถิติไม่สำเร็จ ' + reason);
            setLoadingForm(false);
        });
    }

    const onEditCategory = async (category: ICategory) => {
        formEdit.resetFields();

        formEdit.setFieldsValue(category);
        setSelectdCategory(category);
        setShowEditCategory(true);
    }

    const onConfirmEditCategory = () => {
        Modal.confirm({
            title: 'Confirm',
            icon: <ExclamationCircleOutlined />,
            content: 'ต้องการบันทึกข้อมูลใช่หรือไม่?',
            okText: 'บันทึก',
            cancelText: 'ยกเลิก',
            onOk: () => onEditCategorySave()
        });
    }

    const onEditCategorySave = async () => {
        setLoadingForm(true);
        formEdit.validateFields().then(async (value) => {
            const data: ICategory = formEdit.getFieldsValue();

            const response = await updateCategory(selectdCategory?.id, data);
            console.log(response.status);
            if (response.status === 200) {
                await getCategories();
                Notification('success', 'แก้ไขหมวดหมู่สำเร็จ');
            } else {
                Notification('error', 'แก้ไขหมวดหมู่ไม่สำเร็จ');
            }
            setShowEditCategory(false);
            setLoadingForm(false);
        }).catch((reason) => {
            Notification('error', 'แก้ไขหมวดหมู่ไม่สำเร็จ ' + reason);
            setLoadingForm(false);
        });
    }

    const onConfirmDeleteCategory = (category: ICategory) => {
        Modal.confirm({
            title: 'Confirm',
            icon: <ExclamationCircleOutlined />,
            content: 'ต้องการลบข้อมูลใช่หรือไม่?',
            okText: 'บันทึก',
            cancelText: 'ยกเลิก',
            onOk: () => onDeleteCategory(category.id)
        });
    }

    const onDeleteCategory = async (id: number | undefined) => {
        setLoadingForm(true);
        const response = await deleteCategory(id);
        if (response.status === 200) {
            await getCategories();
            Notification('success', 'ลบข้อมูลสำเร็จ');
        } else {
            Notification('error', 'ลบข้อมูลไม่สำเร็จ');
        }
        setLoadingForm(false);
    }

    return (
        <Spin spinning={loading}>
            <Modal
                title={<Title level={5}>เพิ่มหมวดหมู่</Title>}
                style={{ top: 25 }}
                visible={showAddCategory}
                onOk={() => onConfirmAddNewCategory()}
                okText="บันทึก"
                onCancel={() => setShowAddCategory(false)}
                cancelText="ยกเลิก"
                width={1000}
            >
                <Form {...layout} form={formAdd}>
                    <Form.Item
                        name="name"
                        label="ชื่อหมวดหมู่"
                        rules={[{ required: true, message: 'กรุณาระบุชื่อหมวดหมู่' }]}
                    >
                        <Input placeholder="ระบุชื่อหมวดหมู่" />
                    </Form.Item>
                </Form>
            </Modal>

            <Modal
                title={<Title level={5}>แก้ไขข้อมูลบทความ</Title>}
                style={{ top: 25 }}
                visible={showEditCategory}
                onOk={() => onConfirmEditCategory()}
                okText="แก้ไขบทความ"
                onCancel={() => setShowEditCategory(false)}
                cancelText="ยกเลิก"
                width={1000}
            >
                <Spin spinning={loadingForm}>
                    {selectdCategory ?
                        <>
                            <Form {...layout} form={formEdit}>
                                <Form.Item
                                    name="name"
                                    label="ชื่อหมวดหมู่"
                                    rules={[{ required: true, message: 'กรุณาระบุชื่อหมวดหมู่' }]}
                                >
                                    <Input placeholder="ระบุชื่อหมวดหมู่" />
                                </Form.Item>
                            </Form>
                        </> : <></>}
                </Spin>
            </Modal>

            <Title level={4}>หมวดหมู่บทความ</Title>
            <Button type="primary" onClick={() => onAddNewCategory()}>เพิ่มหมวดหมู่บทความ</Button>
            <Table style={{ marginTop: 10 }} size="small" columns={renderCategoryColumns(onEditCategory, onConfirmDeleteCategory)} dataSource={categories ? categories : []} scroll={{ x: 1100 }} pagination={{ defaultPageSize: 50, position: ['bottomRight'] }} locale={{ emptyText: <Empty description={<b>ไม่มีข้อมูล</b>} /> }} />
        </Spin>
    );
}

export default Category;