import React, { FC, useEffect, useState } from "react";
import { Layout, Menu, Button, Dropdown, Card, Avatar, Spin } from 'antd';
import { Link, Switch, Route, BrowserRouter, useHistory } from 'react-router-dom';
import { MenuFoldOutlined, LogoutOutlined } from '@ant-design/icons';
import './main.css';
import routes, { MyRoute } from "../../routes/routes";
import { logout } from "../../services/services";

const { Header, Sider, Content } = Layout;

const { Meta } = Card;

const Main: FC = () => {
    let history = useHistory();
    const [collapsed, setCollapsed] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (!localStorage.getItem('token')) {
            history.push('/login');
        }
    })

    const logoutSession = async () => {
        setLoading(true);
        await logout();
        setTimeout(() => {
            setLoading(false);
            history.push('/login');
        }, 1500);
    }

    const renderMenu = () => {
        return (
            <Menu>
                <Menu.Item key={1}>
                    <Card
                        style={{ width: 300 }}
                        actions={[
                            <LogoutOutlined type="logout" key="logout" onClick={() => logoutSession()} />,
                        ]}
                    >
                        <Meta
                            avatar={<Avatar src="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png" />}
                            title={"ADMINISTRATOR"}
                            description={"ผู้ดูแลระบบ"}
                        />
                    </Card>
                </Menu.Item>
            </Menu>
        )
    }

    const toggle = () => {
        setCollapsed(!collapsed);
    };

    return (
        <BrowserRouter>
            <Spin spinning={loading}>
                <div>
                    <Layout className="layout" style={{ height: '100%' }}>
                        <Sider trigger={null} collapsible collapsed={collapsed}>
                            <div className="sider">Doohuay24</div>
                            <Menu theme="dark" mode="inline" defaultSelectedKeys={['1']}>
                                {
                                    routes.map((route: MyRoute, idx) => {
                                        if (route.isVisible) {
                                            return <Menu.Item key={idx + 1}>
                                                <Link to={route.path} className="nav-text">
                                                    {route.icon}
                                                    <span>{route.nameTh}</span>
                                                </Link>
                                            </Menu.Item>
                                        }
                                    })
                                }
                            </Menu>
                        </Sider>
                        <Layout>
                            <Header style={{ padding: 0, backgroundColor: "white" }}>
                                <MenuFoldOutlined
                                    className="trigger"
                                    style={{ paddingLeft: 22, fontSize: 20, color: 'grey' }}
                                    onClick={() => toggle()}
                                />

                                <Dropdown overlay={renderMenu()} placement="bottomLeft">
                                    <Button style={{ float: 'right', marginRight: 10, marginTop: 15 }}>โปรไฟล์</Button>
                                </Dropdown>

                            </Header>
                            <Content className="content">
                                <Switch>
                                    {
                                        routes.map((route, idx) => {
                                            return route.component
                                                ? (<Route key={idx} path={route.path} exact={route.exact} render={props => (<route.component {...props} />)} />)
                                                : (null);
                                        })
                                    }
                                </Switch>
                            </Content>
                        </Layout>
                    </Layout>
                </div>
            </Spin>
        </BrowserRouter>
    )
}

export default Main;