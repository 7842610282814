import { Button, DatePicker, Divider, Empty, Form, Input, Modal, Select, Space, Spin, Table, Tag, Typography } from 'antd';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { createNewLottoHistory, deleteLottoHistory, getLotto, getLottoHistoryByLottoId, updateLottoHistory } from '../../services/services';
import { ILottoHistory } from '../../types/lotto-history.types';
import { ILotto } from '../../types/lotto.types';
import { IResponseData } from '../../types/response.type';
import moment from 'moment';
import 'moment/locale/th';
import { ColumnsType } from 'antd/lib/table';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import Notification from '../../components/Notification';

moment.locale('th')
const { Title } = Typography;

const renderLottoHistoryColumns = (onEditLottoHistory: Function, onConfirmDeleteLottoHistory: Function) => {
    const columns: ColumnsType<ILottoHistory> = [
        {
            title: () => <b>ลำดับ</b>,
            dataIndex: 'id',
            key: 'id',
            align: 'center',
            render: (text: string, record: ILottoHistory, index: number) => {
                return <>{index + 1}</>
            }
        },
        {
            title: () => <b>วันที่</b>,
            dataIndex: 'date',
            key: 'date',
            render: (text: string, record: ILottoHistory, index: number) => {
                return text ? moment(text, "YYYY-MM-DD").format("DD MMMM ") + moment(text, "YYYY").add(543, 'years').format("YYYY") : <Tag color="orange">ไม่มีบันทึก</Tag>;
            }
        },
        {
            title: () => <b>เลข 6 ตัว</b>,
            dataIndex: 'up6',
            key: 'up6',
            render: (text: string, record: ILottoHistory, index: number) => {
                return text ? text : <Tag color="orange">ไม่มีบันทึก</Tag>;
            }
        },
        {
            title: () => <b>เลข 5 ตัว</b>,
            dataIndex: 'up5',
            key: 'up5',
            render: (text: string, record: ILottoHistory, index: number) => {
                return text ? text : <Tag color="orange">ไม่มีบันทึก</Tag>;
            }
        },
        {
            title: () => <b>เลข 4 ตัว</b>,
            dataIndex: 'up4',
            key: 'up4',
            render: (text: string, record: ILottoHistory, index: number) => {
                return text ? text : <Tag color="orange">ไม่มีบันทึก</Tag>;
            }
        },
        {
            title: () => <b>เลขบน 3 ตัว</b>,
            dataIndex: 'up3',
            key: 'up3',
            render: (text: string, record: ILottoHistory, index: number) => {
                return text ? text : <Tag color="orange">ไม่มีบันทึก</Tag>;
            }
        },
        {
            title: () => <b>เลขล่าง 2 ตัว</b>,
            dataIndex: 'down2',
            key: 'down2',
            render: (text: string, record: ILottoHistory, index: number) => {
                return text ? text : <Tag color="orange">ไม่มีบันทึก</Tag>;
            }
        },
        {
            title: () => <b>จัดการ</b>,
            key: 'action',
            fixed: 'right',
            render: (text: string, record: ILottoHistory, index: number) => (
                <Space size="middle">
                    <Button size="small" type="primary" onClick={() => onEditLottoHistory(record)}>แก้ไข</Button>
                    <Button size="small" danger onClick={() => onConfirmDeleteLottoHistory(record)}>ลบ</Button>
                </Space>
            ),
        },
    ];

    return columns;
}

const layout = {
    labelCol: {
        span: 4,
    },
    wrapperCol: {
        span: 16,
    },
};

const Statistics: FC = () => {
    // form
    const [formAdd] = Form.useForm();
    const [formEdit] = Form.useForm();

    const [loading, setLoading] = useState(true);
    const [loadingForm, setLoadingForm] = useState(false);
    const [selectdLotto, setSelectedLotto] = useState<ILotto | null>(null);
    const [lotto, setLotto] = useState<IResponseData | null>(null);
    const [lottoHistories, setLottoHistories] = useState<IResponseData | null>(null);
    const [selectdlottoHistory, setSelectdlottoHistory] = useState<ILottoHistory | null>(null);
    const [showEditLottoHistory, setShowEditLottoHistory] = useState(false);
    const [showAddLottoHistory, setShowAddLottoHistory] = useState(false);

    const getLottoData = useCallback(async () => {
        const response = await getLotto();
        setLotto(response.data);
    }, []);

    const getLottoHistoryByLottoIdData = async (id: number | undefined) => {
        const response = await getLottoHistoryByLottoId(id);
        setLottoHistories(response.data);
    }

    useEffect(() => {
        getLottoData().then(() => setLoading(false)).catch(console.error);
    }, [getLottoData]);

    const changeLotto = async (e: any) => {
        setLoading(true);

        const selected: ILotto = lotto?.data[e];
        setSelectedLotto(selected);

        await getLottoHistoryByLottoIdData(selected.id);

        setLoading(false);
    }

    const onAddNewLottoHistory = async () => {
        formAdd.resetFields();
        setShowAddLottoHistory(true);
    }

    const onConfirmAddNewLottoHistory = () => {
        Modal.confirm({
            title: 'Confirm',
            icon: <ExclamationCircleOutlined />,
            content: 'ต้องการบันทึกข้อมูลใช่หรือไม่?',
            okText: 'บันทึก',
            cancelText: 'ยกเลิก',
            onOk: () => onSaveNewLottoHistory()
        });
    }

    const onSaveNewLottoHistory = async () => {
        setLoadingForm(true);
        formAdd.validateFields().then(async (value) => {
            let data: ILottoHistory = formAdd.getFieldsValue();

            data.lotto = selectdLotto?.id;
            data.date = moment(data.date).format("YYYY-MM-DD");
            console.log(data);
            
            const response = await createNewLottoHistory(data);
            if (response.status === 201) {
                await getLottoHistoryByLottoIdData(selectdLotto?.id);
                Notification('success', 'บันทึกสถิติสำเร็จ');
            } else {
                Notification('error', 'บันทึกสถิติไม่สำเร็จ');
            }
            setShowAddLottoHistory(false);
            setLoadingForm(false);
        }).catch((reason) => {
            // console.log(reason);
            Notification('error', 'บันทึกสถิติไม่สำเร็จ ' + reason);
            setLoadingForm(false);
        });
    }

    const onEditLottoHistory = async (lottoHistory: ILottoHistory) => {
        formEdit.resetFields();

        if (lottoHistory.date) lottoHistory.date = moment(lottoHistory.date);

        formEdit.setFieldsValue(lottoHistory);
        setSelectdlottoHistory(lottoHistory);
        setShowEditLottoHistory(true);
    }

    const onConfirmDeleteLottoHistory = (lottoHistory: ILottoHistory) => {
        Modal.confirm({
            title: 'Confirm',
            icon: <ExclamationCircleOutlined />,
            content: 'ต้องการลบข้อมูลใช่หรือไม่?',
            okText: 'บันทึก',
            cancelText: 'ยกเลิก',
            onOk: () => onDeleteLottoHistory(lottoHistory.id)
        });
    }

    const onDeleteLottoHistory = async (id: number|undefined) => {
        setLoadingForm(true);
        const response = await deleteLottoHistory(id);
        if (response.status === 200) {
            await getLottoHistoryByLottoIdData(selectdLotto?.id);
            Notification('success', 'ลบข้อมูลสำเร็จ');
        } else {
            Notification('error', 'ลบข้อมูลไม่สำเร็จ');
        }
        setLoadingForm(false);
    }

    const onConfirmEditLottoHistory = () => {
        Modal.confirm({
            title: 'Confirm',
            icon: <ExclamationCircleOutlined />,
            content: 'ต้องการบันทึกข้อมูลใช่หรือไม่?',
            okText: 'บันทึก',
            cancelText: 'ยกเลิก',
            onOk: () => onEditSave()
        });
    }

    const onEditSave = async () => {
        setLoadingForm(true);
        formEdit.validateFields().then(async (value) => {
            const data = formEdit.getFieldsValue();

            const response = await updateLottoHistory(selectdlottoHistory?.id, data);
            console.log(response.status);
            if (response.status === 200) {
                await getLottoHistoryByLottoIdData(selectdLotto?.id);
                Notification('success', 'แก้ไขสถิติสำเร็จ');
            } else {
                Notification('error', 'แก้ไขสถิติไม่สำเร็จ');
            }
            setShowEditLottoHistory(false);
            setLoadingForm(false);
        }).catch((reason) => {
            Notification('error', 'แก้ไขสถิติไม่สำเร็จ ' + reason);
            setLoadingForm(false);
        });
    }

    return (
        <Spin spinning={loading}>
            <Title level={4}>หน้าสถิติ {selectdLotto?.name}</Title>
            {/* <Divider /> */}
            <Space>
                <Select
                    onChange={(e) => changeLotto(e)}
                    showSearch
                    style={{ width: 250 }}
                    placeholder="ค้นหาด้วยชื่อหวย"
                    optionFilterProp="children"
                    filterOption={(input, option) => (option!.children as unknown as string).includes(input)}
                    filterSort={(optionA, optionB) =>
                        (optionA!.children as unknown as string)
                            .toLowerCase()
                            .localeCompare((optionB!.children as unknown as string).toLowerCase())
                    }
                >
                    {
                        lotto !== null ? lotto.data.map((v: ILotto, index: number) => {
                            return <Select.Option key={v.id} value={index}>{v.name}</Select.Option>
                        }) : <></>
                    }
                </Select>
                <Button disabled={selectdLotto ? false : true} type="primary" onClick={() => onAddNewLottoHistory()}>เพิ่มสถิติ{selectdLotto?.name}</Button>
            </Space>
            <Table style={{ marginTop: 10 }} size="small" columns={renderLottoHistoryColumns(onEditLottoHistory, onConfirmDeleteLottoHistory)} dataSource={lottoHistories?.data} scroll={{ x: 1100 }} pagination={{ defaultPageSize: 50, position: ['bottomRight'] }} locale={{ emptyText: <Empty description={<b>ไม่มีข้อมูล</b>} /> }} />

            <Modal
                title={<Title level={5}>เพิ่มสถิติ {selectdlottoHistory?.lotto?.name}</Title>}
                style={{ top: 25 }}
                visible={showAddLottoHistory}
                onOk={() => onConfirmAddNewLottoHistory()}
                okText="บันทึก"
                onCancel={() => setShowAddLottoHistory(false)}
                cancelText="ยกเลิก"
                width={1000}
            >
                <Spin spinning={loadingForm}>
                    <Form {...layout} form={formAdd}>
                        <Form.Item
                            name="date"
                            label="วันที่ออกผล"
                        >
                            <DatePicker placeholder="วันที่ออกผล" style={{ width: '100%' }} />
                        </Form.Item>
                        <Form.Item
                            name="up6"
                            label="เลข 6 ตัว"
                        >
                            <Input placeholder="ระบุเลข 6 ตัว" />
                        </Form.Item>
                        <Form.Item
                            name="up5"
                            label="เลข 5 ตัว"
                        >
                            <Input placeholder="ระบุเลข 5 ตัว" />
                        </Form.Item>
                        <Form.Item
                            name="up4"
                            label="เลข 4 ตัว"
                        >
                            <Input placeholder="ระบุเลข 4 ตัว" />
                        </Form.Item>
                        <Form.Item
                            name="up3"
                            label="เลขบน 3 ตัว"
                        >
                            <Input placeholder="ระบุเลขบน 3 ตัว" />
                        </Form.Item>
                        <Form.Item
                            name="down2"
                            label="เลขล่าง 2 ตัว"
                        >
                            <Input placeholder="ระบุเลขล่าง 2 ตัว" />
                        </Form.Item>
                    </Form>
                </Spin>
            </Modal>
            <Modal
                title={<Title level={5}>แก้ไขข้อมูลสถิติ {selectdlottoHistory?.lotto?.name} วันที่ {moment(selectdlottoHistory?.date, "YYYY-MM-DD").format("DD MMMM ") + moment(selectdlottoHistory?.date, "YYYY").add(543, 'years').format("YYYY")}</Title>}
                style={{ top: 25 }}
                visible={showEditLottoHistory}
                onOk={() => onConfirmEditLottoHistory()}
                okText="แก้ไขสถิติ"
                onCancel={() => setShowEditLottoHistory(false)}
                cancelText="ยกเลิก"
                width={1000}
            >
                <Spin spinning={loadingForm}>
                    {selectdlottoHistory ?
                        <>
                            <Form {...layout} form={formEdit}>
                                <Form.Item
                                    name="date"
                                    label="วันที่ออกผล"
                                >
                                    <DatePicker placeholder="วันที่ออกผล" style={{ width: '100%' }} />
                                </Form.Item>
                                <Form.Item
                                    name="up6"
                                    label="เลข 6 ตัว"
                                >
                                    <Input placeholder="ระบุเลข 6 ตัว" />
                                </Form.Item>
                                <Form.Item
                                    name="up5"
                                    label="เลข 5 ตัว"
                                >
                                    <Input placeholder="ระบุเลข 5 ตัว" />
                                </Form.Item>
                                <Form.Item
                                    name="up4"
                                    label="เลข 4 ตัว"
                                >
                                    <Input placeholder="ระบุเลข 4 ตัว" />
                                </Form.Item>
                                <Form.Item
                                    name="up3"
                                    label="เลขบน 3 ตัว"
                                >
                                    <Input placeholder="ระบุเลขบน 3 ตัว" />
                                </Form.Item>
                                <Form.Item
                                    name="down2"
                                    label="เลขล่าง 2 ตัว"
                                >
                                    <Input placeholder="ระบุเลขล่าง 2 ตัว" />
                                </Form.Item>
                            </Form>
                        </>
                        : <></>}
                </Spin>
            </Modal>
        </Spin>
    );
}

export default Statistics;
