import { ExclamationCircleOutlined, UploadOutlined } from "@ant-design/icons";
import { Button, Empty, Form, Image, Input, Modal, Select, Space, Spin, Table, Tag, Typography, Upload } from "antd";
import { ColumnsType } from "antd/lib/table";
import moment from "moment";
import React, { FC, useCallback, useEffect, useState } from "react";
import Notification from "../../components/Notification";
import { createNewArticle, deleteArticle, getAllCategories, getArticlesByCategoryId, updateArticle } from "../../services/services";
import { IArticle } from "../../types/article.typs";
import { ICategory } from "../../types/category.types";
import { convertBase64 } from "../../utils/utils";
import exampleImage from '../../assets/img/example.png';

const renderArticleColumns = (onEditArticle: Function, onConfirmDeleteArticle: Function) => {
    const columns: ColumnsType<IArticle> = [
        {
            title: () => <b>ลำดับ</b>,
            dataIndex: 'id',
            key: 'id',
            align: 'center',
            width: 100,
            render: (text: string, record: IArticle, index: number) => {
                return <>{index + 1}</>
            }
        },
        {
            title: () => <b>วันที่</b>,
            dataIndex: 'createdAt',
            key: 'createdAt',
            render: (text: string, record: IArticle, index: number) => {
                return text ? moment(text, "YYYY-MM-DD").format("DD MMMM ") + moment(text, "YYYY").add(543, 'years').format("YYYY") : <Tag color="orange">ไม่มีบันทึก</Tag>;
            }
        },
        {
            title: () => <b>หัวข้อบทความ</b>,
            dataIndex: 'title',
            key: 'title'
        },
        {
            title: () => <b>ผู้เขียนบทความ</b>,
            dataIndex: 'poster',
            key: 'poster'
        },
        {
            title: () => <b>จัดการ</b>,
            key: 'action',
            fixed: 'right',
            render: (text: string, record: IArticle, index: number) => (
                <Space size="middle">
                    <Button size="small" type="primary" onClick={() => onEditArticle(record)}>แก้ไข</Button>
                    <Button size="small" danger onClick={() => onConfirmDeleteArticle(record)}>ลบ</Button>
                </Space>
            ),
        },
    ];

    return columns;
}

const { Title } = Typography;

const layout = {
    labelCol: {
        span: 4,
    },
    wrapperCol: {
        span: 16,
    },
};

const Article: FC = () => {
    // form
    const [formAdd] = Form.useForm();
    const [formEdit] = Form.useForm();

    const [loading, setLoading] = useState(true);
    const [loadingForm, setLoadingForm] = useState(false);
    const [categories, setCategories] = useState<ICategory[] | null>(null);
    const [seletedCategoty, setSelectdCategory] = useState<ICategory | null>(null);
    const [articles, setArticles] = useState<IArticle[] | null>(null);
    const [selectedArticle, setSelectedArticle] = useState<IArticle | null>(null);
    const [showEditArticle, setShowEditArticle] = useState(false);
    const [showAddArticle, setShowAddArticle] = useState(false);
    const [showExampleImage, setShowExampleImage] = useState(false);

    const getCategories = useCallback(async () => {
        const response = await getAllCategories();
        setCategories(response.data?.data);
    }, []);

    const getArticlesByCategory = async (id: number | undefined) => {
        const response = await getArticlesByCategoryId(id);
        setArticles(response.data);
    }

    useEffect(() => {
        getCategories().then(() => setLoading(false)).catch(() => setLoading(false));
    }, [getCategories]);

    const categoryChange = async (e: any) => {
        setLoading(true);

        const selected: ICategory | null = categories ? categories[e] : null;
        setSelectdCategory(categories ? categories[e] : null);

        await getArticlesByCategory(selected?.id);

        setLoading(false);
    }

    const onAddNewArticle = async () => {
        formAdd.resetFields();
        setShowAddArticle(true);
    }

    const onConfirmAddNewArticle = () => {
        Modal.confirm({
            title: 'Confirm',
            icon: <ExclamationCircleOutlined />,
            content: 'ต้องการบันทึกข้อมูลใช่หรือไม่?',
            okText: 'บันทึก',
            cancelText: 'ยกเลิก',
            onOk: () => onSaveNewArticle()
        });
    }

    const onSaveNewArticle = async () => {
        setLoadingForm(true);
        formAdd.validateFields().then(async (value) => {

            const rawData = formAdd.getFieldsValue();

            if (rawData.imageTitle) {
                if (rawData.imageTitle.fileList.length !== 0) {
                    const base64 = await convertBase64(rawData.imageTitle.fileList[0].originFileObj);
                    rawData.imageTitle = base64;
                } else {
                    delete rawData.imageTitle;
                }
            }

            if (rawData.imageParagraph1) {
                if (rawData.imageParagraph1.fileList.length !== 0) {
                    const base64 = await convertBase64(rawData.imageParagraph1.fileList[0].originFileObj);
                    rawData.imageParagraph1 = base64;
                } else {
                    delete rawData.imageParagraph1;
                }
            }

            if (rawData.imageParagraph2) {
                if (rawData.imageParagraph2.fileList.length !== 0) {
                    const base64 = await convertBase64(rawData.imageParagraph2.fileList[0].originFileObj);
                    rawData.imageParagraph2 = base64;
                } else {
                    delete rawData.imageParagraph2;
                }
            }

            if (rawData.imageParagraph3) {
                if (rawData.imageParagraph3.fileList.length !== 0) {
                    const base64 = await convertBase64(rawData.imageParagraph3.fileList[0].originFileObj);
                    rawData.imageParagraph3 = base64;
                } else {
                    delete rawData.imageParagraph3;
                }
            }

            let data: IArticle = rawData;
            data.category = seletedCategoty?.id;
            const response = await createNewArticle(data);
            if (response.status === 201) {
                await getArticlesByCategory(seletedCategoty?.id);
                Notification('success', 'บันทึกสถิติสำเร็จ');
            } else {
                Notification('error', 'บันทึกสถิติไม่สำเร็จ');
            }
            setShowAddArticle(false);
            setLoadingForm(false);
        }).catch((reason) => {
            Notification('error', 'บันทึกสถิติไม่สำเร็จ ' + reason);
            setLoadingForm(false);
        });
    }

    const onEditArticle = async (article: IArticle) => {
        formEdit.resetFields();

        delete article.imageTitle;
        delete article.imageParagraph1;
        delete article.imageParagraph2;
        delete article.imageParagraph3;

        formEdit.setFieldsValue(article);
        setSelectedArticle(article);
        setShowEditArticle(true);
    }

    const onConfirmEditArticle = () => {
        Modal.confirm({
            title: 'Confirm',
            icon: <ExclamationCircleOutlined />,
            content: 'ต้องการบันทึกข้อมูลใช่หรือไม่?',
            okText: 'บันทึก',
            cancelText: 'ยกเลิก',
            onOk: () => onEditSave()
        });
    }

    const onEditSave = async () => {
        setLoadingForm(true);
        formEdit.validateFields().then(async (value) => {
            const rawData = formEdit.getFieldsValue();

            if (rawData.imageTitle) {
                if (rawData.imageTitle.fileList.length !== 0) {
                    const base64 = await convertBase64(rawData.imageTitle.fileList[0].originFileObj);
                    rawData.imageTitle = base64;
                } else {
                    delete rawData.imageTitle;
                }
            } else {
                delete rawData.imageTitle;
            }

            if (rawData.imageParagraph1) {
                if (rawData.imageParagraph1.fileList.length !== 0) {
                    const base64 = await convertBase64(rawData.imageParagraph1.fileList[0].originFileObj);
                    rawData.imageParagraph1 = base64;
                } else {
                    delete rawData.imageParagraph1;
                }
            } else {
                delete rawData.imageParagraph1;
            }

            if (rawData.imageParagraph2) {
                if (rawData.imageParagraph2.fileList.length !== 0) {
                    const base64 = await convertBase64(rawData.imageParagraph2.fileList[0].originFileObj);
                    rawData.imageParagraph2 = base64;
                } else {
                    delete rawData.imageParagraph2;
                }
            } else {
                delete rawData.imageParagraph2;
            }

            if (rawData.imageParagraph3) {
                if (rawData.imageParagraph3.fileList.length !== 0) {
                    const base64 = await convertBase64(rawData.imageParagraph3.fileList[0].originFileObj);
                    rawData.imageParagraph3 = base64;
                } else {
                    delete rawData.imageParagraph3;
                }
            } else {
                delete rawData.imageParagraph3;
            }

            const data: IArticle = rawData;

            const response = await updateArticle(selectedArticle?.id, data);
            // console.log(response.status);
            if (response.status === 200) {
                await getArticlesByCategory(seletedCategoty?.id);
                Notification('success', 'แก้ไขข้อมูลสำเร็จ');
            } else {
                Notification('error', 'แก้ไขสถิติข้อมูลไม่สำเร็จ');
            }
            setShowEditArticle(false);
            setLoadingForm(false);
        }).catch((reason) => {
            Notification('error', 'แก้ไขข้อมูลไม่สำเร็จ ' + reason);
            setLoadingForm(false);
        });
    }

    const onConfirmDeleteArticle = (article: IArticle) => {
        Modal.confirm({
            title: 'Confirm',
            icon: <ExclamationCircleOutlined />,
            content: 'ต้องการลบข้อมูลใช่หรือไม่?',
            okText: 'บันทึก',
            cancelText: 'ยกเลิก',
            onOk: () => onDeleteArticle(article.id)
        });
    }

    const onDeleteArticle = async (id: number | undefined) => {
        setLoadingForm(true);
        const response = await deleteArticle(id);
        if (response.status === 200) {
            await getArticlesByCategory(seletedCategoty?.id);
            Notification('success', 'ลบข้อมูลสำเร็จ');
        } else {
            Notification('error', 'ลบข้อมูลไม่สำเร็จ');
        }
        setLoadingForm(false);
    }



    return (
        <Spin spinning={loading}>
            <Image
                hidden
                preview={{
                    visible: showExampleImage,
                    onVisibleChange: (visible, prevVisible) => setShowExampleImage(visible)
                }}
                width={301}
                height={529}
                src={exampleImage}
            />
            <Title level={4}>หน้าบทความ {seletedCategoty?.name}</Title>
            <Space>
                <Select
                    placeholder="เลือกหมวดหมู่"
                    style={{ width: 200 }}
                    onChange={categoryChange}
                >
                    {
                        categories?.map((category: ICategory, index: number) => {
                            return <Select.Option key={index}>{category.name}</Select.Option>
                        })
                    }
                </Select>

                <Button disabled={seletedCategoty ? false : true} type="primary" onClick={() => onAddNewArticle()}>เพิ่ม{seletedCategoty?.name}</Button>
                <Button type="default" onClick={() => setShowExampleImage(!showExampleImage)}>ตัวอย่างการเพิ่มบทความ</Button>
            </Space>

            <Table style={{ marginTop: 10 }} size="small" columns={renderArticleColumns(onEditArticle, onConfirmDeleteArticle)} dataSource={articles ? articles : []} scroll={{ x: 1100 }} pagination={{ defaultPageSize: 50, position: ['bottomRight'] }} locale={{ emptyText: <Empty description={<b>ไม่มีข้อมูล</b>} /> }} />

            <Modal
                title={<Title level={5}>เพิ่ม{seletedCategoty?.name}</Title>}
                style={{ top: 25 }}
                visible={showAddArticle}
                onOk={() => onConfirmAddNewArticle()}
                okText="บันทึก"
                onCancel={() => setShowAddArticle(false)}
                cancelText="ยกเลิก"
                width={1000}
                maskClosable={false}
            >
                <Spin spinning={loadingForm}>
                    <Form {...layout} form={formAdd}>
                        <Form.Item
                            name="poster"
                            label="ผู้เขียน"
                        >
                            <Input placeholder="ระบุชื่อผู้เขียน" />
                        </Form.Item>
                        <Form.Item
                            name="imageTitle"
                            label="รูปภาพหัวข้อ"
                        >
                            <Upload
                                beforeUpload={() => false}
                                multiple={false}
                                maxCount={1}
                                accept=".png,.jpg,jpeg"
                                onRemove={(prop: any) => {
                                    console.log(prop);

                                    return undefined;
                                }}>
                                <Button icon={<UploadOutlined />}>คลิกเพื่ออัพโหลดรูปภาพ</Button>
                            </Upload>
                        </Form.Item>
                        <Form.Item
                            name="title"
                            label="หัวข้อ"
                        >
                            <Input placeholder="ระบุหัวข้อ" />
                        </Form.Item>
                        <Form.Item
                            name="paragraph1"
                            label="ย่อหน้าแรก"
                        >
                            <Input.TextArea placeholder="ระบุย่อหน้าแรก" rows={6} style={{ resize: 'none' }} />
                        </Form.Item>
                        <Form.Item
                            name="paragraph2"
                            label="ย่อหน้าที่สอง"
                        >
                            <Input.TextArea placeholder="ระบุย่อหน้าที่สอง" rows={6} style={{ resize: 'none' }} />
                        </Form.Item>
                        <Form.Item
                            name="blockquote"
                            label="คำสำคัญ"
                        >
                            <Input placeholder="ระบุคำสำคัญ" />
                        </Form.Item>
                        <Form.Item
                            name="paragraph3"
                            label="ย่อหน้าที่สาม"
                        >
                            <Input.TextArea placeholder="ระบุย่อหน้าที่สาม" rows={6} style={{ resize: 'none' }} />
                        </Form.Item>
                        <Form.Item
                            name="imageParagraph1"
                            label="รูปภาพล่าง (1)"
                        >
                            <Upload
                                beforeUpload={() => false}
                                multiple={false}
                                maxCount={1}
                                accept=".png,.jpg,jpeg"
                                onRemove={(prop: any) => {
                                    console.log(prop);

                                    return undefined;
                                }}>
                                <Button icon={<UploadOutlined />}>คลิกเพื่ออัพโหลดรูปภาพ</Button>
                            </Upload>
                        </Form.Item>
                        <Form.Item
                            name="imageParagraph2"
                            label="รูปภาพล่าง (2)"
                        >
                            <Upload
                                beforeUpload={() => false}
                                multiple={false}
                                maxCount={1}
                                accept=".png,.jpg,jpeg"
                                onRemove={(prop: any) => {
                                    console.log(prop);

                                    return undefined;
                                }}>
                                <Button icon={<UploadOutlined />}>คลิกเพื่ออัพโหลดรูปภาพ</Button>
                            </Upload>
                        </Form.Item>
                        <Form.Item
                            name="imageParagraph3"
                            label="รูปภาพล่าง (3)"
                        >
                            <Upload
                                beforeUpload={() => false}
                                multiple={false}
                                maxCount={1}
                                accept=".png,.jpg,jpeg"
                                onRemove={(prop: any) => {
                                    console.log(prop);

                                    return undefined;
                                }}>
                                <Button icon={<UploadOutlined />}>คลิกเพื่ออัพโหลดรูปภาพ</Button>
                            </Upload>
                        </Form.Item>
                        <Form.Item
                            name="paragraphEnd"
                            label="ย่อหน้าสุดท้าย"
                        >
                            <Input.TextArea placeholder="ระบุย่อหน้าสุดท้าย" rows={8} style={{ resize: 'none' }} />
                        </Form.Item>
                    </Form>
                </Spin>
            </Modal>

            <Modal
                title={<Title level={5}>แก้ไข{seletedCategoty?.name}</Title>}
                style={{ top: 25 }}
                visible={showEditArticle}
                onOk={() => onConfirmEditArticle()}
                okText="บันทึก"
                onCancel={() => setShowEditArticle(false)}
                cancelText="ยกเลิก"
                width={1000}
                maskClosable={false}
            >
                <Spin spinning={loadingForm}>
                    {selectedArticle ?
                        <Form {...layout} form={formEdit}>
                            <Form.Item
                                name="poster"
                                label="ผู้เขียน"
                            >
                                <Input placeholder="ระบุชื่อผู้เขียน" />
                            </Form.Item>
                            <Form.Item
                                name="imageTitle"
                                label="รูปภาพหัวข้อ"
                            >
                                <Upload
                                    beforeUpload={() => false}
                                    multiple={false}
                                    maxCount={1}
                                    accept=".png,.jpg,jpeg"
                                    onRemove={(prop: any) => {
                                        console.log(prop);

                                        return undefined;
                                    }}>
                                    <Button icon={<UploadOutlined />}>คลิกเพื่ออัพโหลดรูปภาพ</Button>
                                </Upload>
                            </Form.Item>
                            <Form.Item
                                name="title"
                                label="หัวข้อ"
                            >
                                <Input placeholder="ระบุหัวข้อ" />
                            </Form.Item>
                            <Form.Item
                                name="paragraph1"
                                label="ย่อหน้าแรก"
                            >
                                <Input.TextArea placeholder="ระบุย่อหน้าแรก" rows={6} style={{ resize: 'none' }} />
                            </Form.Item>
                            <Form.Item
                                name="paragraph2"
                                label="ย่อหน้าที่สอง"
                            >
                                <Input.TextArea placeholder="ระบุย่อหน้าที่สอง" rows={6} style={{ resize: 'none' }} />
                            </Form.Item>
                            <Form.Item
                                name="blockquote"
                                label="คำสำคัญ"
                            >
                                <Input placeholder="ระบุคำสำคัญ" />
                            </Form.Item>
                            <Form.Item
                                name="paragraph3"
                                label="ย่อหน้าที่สาม"
                            >
                                <Input.TextArea placeholder="ระบุย่อหน้าที่สาม" rows={6} style={{ resize: 'none' }} />
                            </Form.Item>
                            <Form.Item
                                name="imageParagraph1"
                                label="รูปภาพล่าง (1)"
                            >
                                <Upload
                                    beforeUpload={() => false}
                                    multiple={false}
                                    maxCount={1}
                                    accept=".png,.jpg,jpeg"
                                    onRemove={(prop: any) => {
                                        console.log(prop);

                                        return undefined;
                                    }}>
                                    <Button icon={<UploadOutlined />}>คลิกเพื่ออัพโหลดรูปภาพ</Button>
                                </Upload>
                            </Form.Item>
                            <Form.Item
                                name="imageParagraph2"
                                label="รูปภาพล่าง (2)"
                            >
                                <Upload
                                    beforeUpload={() => false}
                                    multiple={false}
                                    maxCount={1}
                                    accept=".png,.jpg,jpeg"
                                    onRemove={(prop: any) => {
                                        console.log(prop);

                                        return undefined;
                                    }}>
                                    <Button icon={<UploadOutlined />}>คลิกเพื่ออัพโหลดรูปภาพ</Button>
                                </Upload>
                            </Form.Item>
                            <Form.Item
                                name="imageParagraph3"
                                label="รูปภาพล่าง (3)"
                            >
                                <Upload
                                    beforeUpload={() => false}
                                    multiple={false}
                                    maxCount={1}
                                    accept=".png,.jpg,jpeg"
                                    onRemove={(prop: any) => {
                                        console.log(prop);

                                        return undefined;
                                    }}>
                                    <Button icon={<UploadOutlined />}>คลิกเพื่ออัพโหลดรูปภาพ</Button>
                                </Upload>
                            </Form.Item>
                            <Form.Item
                                name="paragraphEnd"
                                label="ย่อหน้าสุดท้าย"
                            >
                                <Input.TextArea placeholder="ระบุย่อหน้าสุดท้าย" rows={8} style={{ resize: 'none' }} />
                            </Form.Item>
                        </Form> : <></>
                    }
                </Spin>
            </Modal>
        </Spin>
    )
}

export default Article;